interface Options {
  0: string;
  1: string;
  other: string;
}

export type OptionProps = 0 | 1 | 'other';

export function localizePlural(quantity: OptionProps, options: Options) {
  const directResult: string = options[quantity];
  if (directResult !== undefined) { return directResult; }
  return options.other;
}
